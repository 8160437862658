import React from "react"
import Nav from "../components/Nav/Nav";
import PassGenerator from "../components/Form/PassGenerator";
import Footer from "../components/Footer/Footer";
import '../styles/styles.css';
export default function NotFound() {
  return (
    <div className="min-h-screen">
      <Nav />
      <div className="w-full max-w-lg mt-10 mx-auto min-h-screen flex flex-col">
        <div className="px-8 pt-6 pb-8 mb-4 text-center text-white">
          <h1 className="text-5xl mb-4 raleway-font"><strong>404</strong></h1>
          <p className="mono-font">Ops, looks like this page doesn't exist</p>
        </div>
        <Footer />
      </div>
    </div>
  )
}
